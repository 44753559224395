<template lang="pug">
include ../../../configs/template
div.col-12
  div.row
    h2.col-12.text-left Нострифікований документ:
  div.row
    div.col-sm-12(v-if="documentInfo.created_at")
      +data-info('createDate','getDateFormat(documentInfo.created_at)')
    div.col-sm-12(v-if="documentInfo.number_document")
      +data-info('docNumber','documentInfo.number_document')
    div.col-sm-12(v-if="documentInfo.serial")
      +data-info('serial','documentInfo.serial')
    div.col-sm-12(v-if="documentInfo.registry_number")
      +data-info('registrationNumber','documentInfo.registry_number')
    div.col-sm-12(v-if="documentInfo.extent")
      +data-info('educationExtent','getDirectoryObject({ value:"extent", id:documentInfo.extent })[nameLang]')
    div.col-sm-12(v-if="documentInfo.qualification")
      +data-info('qualification','getDirectoryObject({ value:"qualificationLevels", id:documentInfo.qualification })[nameLang]')
    div.col-sm-12(v-if="documentInfo.speciality")
      +data-info('specialty','getDirectoryObject({ value: "profession", id: documentInfo.speciality})[nameLang]')
    div.col-sm-12(v-if="documentInfo.specialization")
      +data-info('specialization','getDirectoryObject({ value: "specialization", id:documentInfo.specialization })[nameLang]')
    div.col-sm-12(v-if="documentInfo.education_document")
      +data-info-link('educationDocument','number_document', 'linkSailorEducationDocument')
    div.col-sm-12(v-if="documentInfo.date_start_educ")
      +data-info('date_start_educ','getDateFormat(documentInfo.date_start_educ)')
    div.col-sm-12(v-if="documentInfo.date_issue_document")
      +data-info('dateIssue','getDateFormat(documentInfo.date_issue_document)')
    div.col-sm-12(v-if="documentInfo.date_end_educ")
      +data-info('date_end_educ','getDateFormat(documentInfo.date_end_educ)')
</template>
<script>
import { getDateFormat } from '@/mixins/main'
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    documentInfo: { type: Object, default: () => ({}) },
    number_document: { type: String, default: '' }
  },
  data () {
    return {
      getDateFormat

    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      nameLang: (state) => (state.main.lang === 'en' ? 'name_eng' : 'name_ukr')
    }),
    linkSailorEducationDocument () {
      return { name: 'education-documents-info', params: { id: this.documentInfo?.sailor?.id, documentID: this.documentInfo?.education_document } }
    }
  }
}
</script>
